<template>
    <section class="single-area area-padding mid-top-padding contract-section">
        <div class="service-fluid">
            <div class="container">
                <div class="row">
                    <div class="col-md-12">
                        <div class="">
                            <h3 class="">
                                Aviso de Privacidad.
                            </h3>
                            <p class="bold primary">
                                Fecha de actualización: 22 de enero de 2025 
                            </p>
                        </div>

                        <div class="text-justify">
                            <strong>Red Girasol, S.A.P.I. de C.V., Institución de Financiamiento Colectivo</strong> (en lo sucesivo <u>“nosotros”</u> y/o
                            <u>“RedGirasol”</u>) hace del conocimiento de sus clientes, proveedores y/o usuarios (cada uno un <u>“Usuario”</u>), el
                            presente Aviso de Privacidad en cumplimiento lo dispuesto por la Ley Federal de Protección de Datos
                            Personales en Posesión de los Particulares (<u>“LFPDPPP”</u>) y sus ordenamientos secundarios. Le informamos
                            que el tratamiento de dichos datos está sujeto a su consentimiento expreso, por lo que, al aceptar en el Sitio
                            el presente Aviso de Privacidad usted autoriza que RedGirasol trate los datos personales que usted
                            proporcione para las finalidades aquí establecidas, informadas en el presente, además de cualquier otra que
                            sea necesaria para cumplir las obligaciones derivadas de la relación jurídica entre usted y RedGirasol, de
                            conformidad con la LFDPPPP. 
                            
                            <br>
                            <br>

                            <strong>1.	Identidad, domicilio e información de contacto del responsable.</strong><br><br>
                            RedGirasol con domicilio en Ave. Eugenio Garza Sada Ext. 3820 Int. 801, Colonia Mas Palomas, Monterrey, Nuevo León, CP.64780, México, será el responsable del tratamiento de los datos personales de los Usuarios, del uso que se da a los mismos y de su protección. El presente Aviso de Privacidad provee información sobre la forma en que RedGirasol, así como sus empresas filiales, usan los datos personales de los Usuarios, y sobre los derechos que los mismos tienen como usuarios del Sitio.<br><br>
                            RedGirasol ha designado a un agente de protección de datos (“<u>DDP</u>” como dicho término se define más adelante) el cual será responsable de la supervisión de las preguntas relacionadas con este Aviso de Privacidad. En caso de tener los Usuarios alguna pregunta relacionada con éste Aviso de Privacidad o si desean obtener información al respecto, deberán ponerse en contacto con el DDP mediante los siguientes datos:<br><br>

                            <strong>Red Girasol, S.A.P.I, de C.V., Institución de Financiamiento Colectivo </strong><br>
                            Ave. Eugenio Garza Sada 3820 interior 801 <br>
                            Colonia Mas Palomas <br>
                            Monterrey, Nuevo León C.P. 64780 <br>
                            <a href="mailto:contacto@redgirasol.com" style="color: blue"><u>contacto@redgirasol.com</u></a> <br>
                            A/A: Departamento de Protección de Datos <br><br>

                            <strong>2.	¿Qué datos personales se recaban de los Usuarios? </strong><br><br>
                            RedGirasol no revelará información de carácter personal e identificable (como ser nombre y dirección de correo electrónico) a menos que los Usuarios, voluntariamente, decidan revelar tales u otros datos para fines específicos, tales como el funcionamiento y operación del Sitio y de la Comunidad Girasol, publicación de proyectos y/o mandar quejas o sugerencias. RedGirasol no venderá, comercializará ni arrendará información de carácter personal e identificable a terceros. La información de los Usuarios podría ser utilizada para la investigación relacionada con patrones de uso, desarrollo de productos, para el funcionamiento del Sitio, generación y publicación de proyectos, personalización de sitios online y marketing dirigido.<br><br>
                            Para las finalidades establecidas en este Aviso de Privacidad, RedGirasol, en ciertos casos pudiera obtener de los Usuarios los siguientes datos personales:<br>
                            <ol class="text-justify" style="list-style-type: upper-roman;font-weight: bold; margin-top: 1rem;">
                                
                                <!-- I. -->
                                <li style="font-size:16px!important"> 
                                    <span style="font-weight: 400">
                                        En caso de Usuarios que sean personas físicas que sean de nacionalidad mexicana o de nacionalidad
                                        extranjera en condiciones de estancia de residente temporal o residente permanente en términos de
                                        la Ley de Migración: 
                                    </span>
                                    <ol class="text-justify" style="list-style-type: lower-alpha;font-weight: bold; margin-top: 1rem;">
                                        <li style="font-size:16px!important">
                                        <!-- A. -->
                                            <span style="font-weight: normal">
                                                Los datos de identificación siguientes, provenientes de un documento válido de identificación personal oficial vigente emitido por autoridad competente o del documento que acredite su legal estancia en el país: 
                                            </span>

                                            <ol class="text-justify" style="list-style-type: lower-roman;font-weight: bold; margin-top: 1rem;">
                                                <li style="font-size:16px!important">
                                                    <span style="font-weight: normal;">Apellido paterno, apellido materno y nombre o nombres sin abreviaturas. </span>
                                                </li>
                                                <li style="font-size:16px!important">
                                                    <span style="font-weight: normal;">Género. </span>
                                                </li>
                                                <li style="font-size:16px!important">
                                                    <span style="font-weight: normal;">Fecha de nacimiento. </span>
                                                </li>
                                                <li style="font-size:16px!important">
                                                    <span style="font-weight: normal;">Entidad Federativa de nacimiento. </span>
                                                </li>
                                                <li style="font-size:16px!important">
                                                    <span style="font-weight: normal;">País de nacimiento. </span>
                                                </li>
                                                <li style="font-size:16px!important">
                                                    <span style="font-weight: normal;">Nacionalidad. </span>
                                                </li>
                                                <li style="font-size:16px!important">
                                                    <span style="font-weight: normal;">Clave de elector, en su caso.</span>
                                                </li>
                                            </ol>
                                        </li><br>
                                        <li style="font-size:16px!important">
                                        <!-- B. -->
                                            <span style="font-weight: normal">
                                                Domicilio particular del país de residencia compuesto por nombre de la calle, avenida o vía de que se trate, debidamente especificada; número exterior y, en su caso, interior; colonia o urbanización; alcaldía, delegación, municipio o demarcación política similar que corresponda, en su caso; ciudad o población, entidad federativa, estado, provincia, departamento o demarcación política similar que corresponda, en su caso; código postal y país. 
                                            </span>
                                        </li><br>
                                        <li style="font-size:16px!important">
                                        <!-- C. -->
                                            <span style="font-weight: normal">
                                                Ocupación, profesión, actividad o giro del negocio al que se dedica el Usuario.
                                            </span>
                                        </li><br>
                                        <li style="font-size:16px!important">
                                        <!-- D. -->
                                            <span style="font-weight: normal">
                                                Clave Única de Registro de Población. 
                                            </span>
                                        </li><br>
                                        <li style="font-size:16px!important">
                                        <!-- E. -->
                                            <span style="font-weight: normal">
                                            Firma Autógrafa Digitalizada. 
                                            </span>
                                        </li><br>
                                        <li style="font-size:16px!important">
                                        <!-- F. -->
                                            <span style="font-weight: normal">
                                                Número telefónico en que se pueda localizar. 
                                            </span>
                                        </li><br>
                                        <li style="font-size:16px!important">
                                        <!-- G. -->
                                            <span style="font-weight: normal">
                                                Dirección de correo electrónico. 
                                            </span>
                                        </li><br>
                                        <!-- H. -->
                                        <li style="font-size:16px!important">
                                            <span style="font-weight: normal;">
                                                En su caso, número de cuenta y/o Clave Bancaria Estandarizada (CLABE) de la Entidad
                                                Financiera o Entidad Financiera Extranjera autorizadas para recibir depósitos; de
                                                conversión a Fondos de Pago Electrónico o bien, y que corresponde con el nombre del
                                                Usuario. 
                                            </span>
                                        </li>
                                    </ol>
                                </li><br>

                                <!-- II. -->
                                <li style="font-size:16px!important"> 
                                    <span style="font-weight: 400">
                                        En caso de Usuarios que sean personas físicas de nacionalidad extranjera y que declaren que no
                                        tienen condición de estancia de residente temporal o residente permanente en términos de la Ley
                                        de Migración:
                                    </span>
                                    <ol class="text-justify" style="list-style-type: lower-alpha;font-weight: bold; margin-top: 1rem;">
                                        <li style="font-size:16px!important">
                                        <!-- A. -->
                                            <span style="font-weight: normal">
                                                Los datos de identificación siguientes, provenientes del pasaporte: 
                                            </span>

                                            <ol class="text-justify" style="list-style-type: lower-roman;font-weight: bold; margin-top: 1rem;">
                                                <li style="font-size:16px!important">
                                                    <span style="font-weight: normal;">Apellido paterno, apellido materno y nombre o nombres sin abreviaturas. </span>
                                                </li>
                                                <li style="font-size:16px!important">
                                                    <span style="font-weight: normal;">Género. </span>
                                                </li>
                                                <li style="font-size:16px!important">
                                                    <span style="font-weight: normal;">Fecha de nacimiento. </span>
                                                </li>
                                                <li style="font-size:16px!important">
                                                    <span style="font-weight: normal;">País de nacimiento. </span>
                                                </li>
                                                <li style="font-size:16px!important">
                                                    <span style="font-weight: normal;">Nacionalidad. </span>
                                                </li>
                                            </ol>
                                        </li><br>
                                        <li style="font-size:16px!important">
                                        <!-- B. -->
                                            <span style="font-weight: normal">
                                                Domicilio particular del país de residencia compuesto por nombre de la calle, avenida o
                                                vía de que se trate, debidamente especificada; número exterior y, en su caso, interior;
                                                colonia o urbanización; alcaldía, delegación, municipio o demarcación política similar que
                                                corresponda, en su caso; ciudad o población, entidad federativa, estado, provincia,
                                                departamento o demarcación política similar que corresponda, código postal y país, en su
                                                caso. 

                                            </span>
                                        </li><br>
                                        <li style="font-size:16px!important">
                                        <!-- C. -->
                                            <span style="font-weight: normal">
                                                Ocupación, profesión, actividad o giro del negocio al que se dedica el Usuario.
                                            </span>
                                        </li><br>
                                        <li style="font-size:16px!important">
                                        <!-- D. -->
                                            <span style="font-weight: normal">
                                                Firma Autógrafa Digitalizada. 
                                            </span>
                                        </li><br>
                                        <li style="font-size:16px!important">
                                        <!-- E. -->
                                            <span style="font-weight: normal">
                                                Número telefónico en que se pueda localizar. 
                                            </span>
                                        </li><br>
                                        <li style="font-size:16px!important">
                                        <!-- F. -->
                                            <span style="font-weight: normal">
                                                Dirección de correo electrónico. 
                                            </span>
                                        </li><br>
                                        <li style="font-size:16px!important">
                                        <!-- F. -->
                                            <span style="font-weight: normal">
                                                Dirección de correo electrónico. 
                                            </span>
                                        </li><br>
                                        <!-- G. -->
                                        <li style="font-size:16px!important">
                                            <span style="font-weight: normal;">
                                                En su caso, número de cuenta y/o Clave Bancaria Estandarizada (CLABE) de la Entidad
                                                Financiera o Entidad Financiera Extranjera autorizadas para recibir depósitos; de
                                                conversión a Fondos de Pago Electrónico o bien, y que corresponde con el nombre del
                                                Usuario. 
                                            </span>
                                        </li><br>
                                        <!-- G. -->
                                        <li style="font-size:16px!important">
                                            <span style="font-weight: normal;">
                                                En su caso, número de cuenta y Clave Bancaria Estandarizada (CLABE) en la Entidad
                                                Financiera o Entidad Financiera Extranjera autorizadas para recibir depósitos; de
                                                conversión a Fondos de Pago Electrónico o bien, de inversión en o de recepción de recursos
                                                de operaciones de financiamiento colectivo, según se trate, y que corresponda con el
                                                nombre del Usuario. 
                                            </span>
                                        </li>
                                    </ol>
                                </li><br>

                                <!-- III. -->
                                <li style="font-size:16px!important">
                                    <span  style="font-weight: 400;">
                                        Tratándose de Usuarios que sean personas morales de nacionalidad mexicana:
                                    </span> 
                                    <ol class="text-justify" style="list-style-type: lower-alpha;font-weight: bold; margin-top: 1rem;">
                                        <!-- A. -->
                                        <li style="font-size:16px!important">
                                            <span style="font-weight: normal">
                                                Los datos de identificación siguientes:
                                            </span>

                                            <ol class="text-justify" style="list-style-type: lower-roman;font-weight: bold; margin-top: 1rem;">
                                                <li style="font-size:16px!important">
                                                    <span style="font-weight: normal;">Denominación o razón social. </span>
                                                </li>
                                                <li style="font-size:16px!important">
                                                    <span style="font-weight: normal;">Giro mercantil, actividad u objeto social. </span>
                                                </li>
                                                <li style="font-size:16px!important">
                                                    <span style="font-weight: normal;">Nacionalidad.  </span>
                                                </li>
                                                <li style="font-size:16px!important">
                                                    <span style="font-weight: normal;">Clave del Registro Federal de Contribuyentes con homoclave y, en su caso, número de identificación fiscal o equivalente, así como el país o países que lo asignaron. </span>
                                                </li>
                                                <li style="font-size:16px!important">
                                                    <span style="font-weight: normal;">Número de serie de la Firma Electrónica Avanzada. </span>
                                                </li>
                                                <li style="font-size:16px!important">
                                                    <span style="font-weight: normal;">Domicilio, compuesto por nombre de la calle, avenida o vía de que se trate, debidamente especificada; número exterior y, en su caso, interior; colonia o urbanización; alcaldía, delegación, municipio o demarcación política similar que corresponda, en su caso; ciudad o población, entidad federativa, estado, provincia, departamento o demarcación política similar que corresponda, en su caso; código postal y país. </span>
                                                </li>
                                                <li style="font-size:16px!important">
                                                    <span style="font-weight: normal;">Número telefónico del domicilio. </span>
                                                </li>
                                                <li style="font-size:16px!important">
                                                    <span style="font-weight: normal;">Dirección de correo electrónico. </span>
                                                </li>
                                                <li style="font-size:16px!important">
                                                    <span style="font-weight: normal;">Fecha de constitución. </span>
                                                </li>
                                                <li style="font-size:16px!important">
                                                    <span style="font-weight: normal;">Apellido paterno, apellido materno y nombre o nombres del administrador o administradores, director, gerente general o apoderado legal que, con su firma, puedan obligar a la persona moral a celebrar la Operación, proveniente de un documento válido de identificación personal oficial vigente emitido por autoridad competente.  </span>
                                                </li>
                                                <li style="font-size:16px!important">
                                                    <span style="font-weight: normal;">Firma Autógrafa Digitalizada del representante legal.</span>
                                                </li>
                                                <li style="font-size:16px!important">
                                                    <span style="font-weight: normal;">La versión digital de los documentos siguientes: </span>
                                                    <ol class="text-justify" style="list-style-type: lower-roman;font-weight: bold; margin-top: 1rem;">
                                                        <li style="font-size:16px!important">
                                                            <span style="font-weight: normal;">
                                                                El documento o el testimonio o copia certificada del instrumento público que
                                                                acredite su legal existencia, inscrito en el registro público correspondiente, o
                                                                cualquier instrumento o documento que acredite fehacientemente su existencia. 
                                                            </span>
                                                        </li>
                                                        <li style="font-size:16px!important">
                                                            <span style="font-weight: normal;">Cédula de Identificación Fiscal expedida por la Secretaría y, en su caso, del documento en el que conste la asignación del número de identificación fiscal o su equivalente expedido por autoridad competente y constancia de la Firma Electrónica Avanzada. </span>
                                                        </li>
                                                        <li style="font-size:16px!important">
                                                            <span style="font-weight: normal;">Comprobante del domicilio. </span>
                                                        </li>
                                                        <li style="font-size:16px!important">
                                                            <span style="font-weight: normal;">Testimonio o copia certificada del instrumento expedido por fedatario público que contenga los poderes del representante o representantes legales, cuando no estén contenidos en el instrumento público que acredite la legal existencia de la persona moral.</span>
                                                        </li>
                                                    </ol>
                                                </li>
                                            </ol>
                                        </li><br>
                                    </ol>
                                    
                                </li>

                                <!-- III. -->
                                <li style="font-size:16px!important">
                                    Datos de IP y navegación: 
                                    <span  style="font-weight: normal;">
                                        Una dirección de Protocolo de Internet (<u>"IP"</u>) consistente en un número que se asigna automáticamente a su computadora toda vez que usted navega en la red. 
                                        Los servidores de red identifican automáticamente su computadora por medio de la dirección IP. Cuando los visitantes interactúan con el Sitio, los servidores de RedGirasol registran la dirección de IP del Usuario. 
                                        Normalmente no se enlazan direcciones IP a ningún dato de carácter personal que pudiera ser identificable, lo cual significa que la sesión de un Usuario se registra, pero para RedGirasol, dicho visitante permanece en el anonimato. 
                                        RedGirasol recolecta direcciones IP para fines de administración de sistemas y para regular el uso del Sitio. Además, RedGirasol utiliza direcciones IP tanto para la recolección de información estadística que será enviada a terceros y/o Prestadores de Servicio como para otros fines de marketing y promoción.
                                    </span> 
                                </li><br>
                                
                                <!-- IV. -->
                                <li style="font-size:16px!important">
                                    Datos Biométricos.
                                    <span  style="font-weight: normal;">
                                        RedGirasol podrá recabar de sus Usuarios los siguientes datos
                                        personales sensibles, con el fin de autenticar la identidad de los Usuarios, o las personas que, en
                                        su caso, las representen: 
                                    </span>  
                                    <ol class="text-justify" style="list-style-type: lower-alpha;font-weight: bold; margin-top: 1rem;">
                                        <li style="font-size:16px!important">
                                            <span style="font-weight: normal;">Reconocimiento facial; </span>
                                        </li>
                                        <li style="font-size:16px!important">
                                            <span style="font-weight: normal;">Reconocimiento de firma; </span>
                                        </li>
                                        <li style="font-size:16px!important">
                                            <span style="font-weight: normal;">Reconocimiento de escritura; y </span>
                                        </li>
                                        <li style="font-size:16px!important">
                                            <span style="font-weight: normal;">Reconocimiento de voz. </span>
                                        </li>
                                    </ol>
                                </li><br>
                            </ol>

                            <p class="text-justify">
                                Cuando el Usuario ingresa al Sitio, éste podrá estar utilizando plataformas digitales, sitios o plataformas de
                                terceros, o bien tecnologías como pueden ser cookies, cookies Flash, píxeles y web bugs, entre otras. En
                                caso de habilitar los Usuarios en su dispositivo una función de prevención de cookies, algunos de los
                                servicios y funcionalidades del Sitio podrán dejar de operar o bien operar de manera diferente. 
                            </p>

                            <br>
                            <strong>3.	¿Para qué se utilizan los datos personales de los Usuarios?</strong>
                            <p class="text-justify">La obtención y tratamiento de los datos personales de Usuarios se realiza para las siguientes finalidades primarias y secundarias, mismas que RedGirasol considera como necesarias para la existencia, mantenimiento y cumplimiento de la Comunidad Girasol y de la relación que se sostiene con los Usuarios: </p>
                            <ol class="text-justify" style="list-style-type: upper-alpha;font-weight: bold;margin-top:1rem;">
                                <li>
                                    Finalidades primarias: 
                                    <ol class="text-justify" style="list-style-type: lower-alpha;font-weight: bold;margin-top:1rem;">
                                        <li style="font-size:16px!important"> <span  style="font-weight: normal;">Proporcionar información de los productos de RedGirasol cuando el Usuario lo solicite;</span> </li><br>
                                        <li style="font-size:16px!important"> <span  style="font-weight: normal;">Verificar y confirmar la identidad, información de contacto y demás relacionada con los Usuarios;</span> </li><br>
                                        <li style="font-size:16px!important"> <span  style="font-weight: normal;">Recibir quejas, y dar seguimiento a solicitudes;</span> </li><br>
                                        <li style="font-size:16px!important"> <span  style="font-weight: normal;">Para encuestas de servicios que permitan a RedGirasol mejorar sus productos;</span> </li><br>
                                        <li style="font-size:16px!important"> <span  style="font-weight: normal;">Para fines de mercadotecnia y/o estudio de mercado, sin fines de lucrar con la información o realizar contactos no deseados;</span> </li><br>
                                        <li style="font-size:16px!important"> <span  style="font-weight: normal;">Emisión de facturas;</span> </li><br>
                                        <li style="font-size:16px!important"> <span  style="font-weight: normal;">Generación de cotizaciones; y</span> </li><br>
                                        <li style="font-size:16px!important"> <span  style="font-weight: normal;">Análisis de factibilidad técnica y económica para proyectos solares y Sistemas.</span> </li>
                                    </ol>
                                </li><br>
                                <li>
                                    Finalidades secundarias: 
                                    <ol class="text-justify" style="list-style-type: lower-alpha;font-weight: bold;margin-top:1rem;">
                                        <li style="font-size:16px!important"> 
                                            <span  style="font-weight: normal;">
                                                Generar y mantener bases de datos y registros, de cualquier naturaleza, respecto de los Usuarios
                                                por el tiempo que éste considere necesario; lo anterior, bajo el principio de mínimos accesos;
                                            </span>
                                        </li><br>
                                        <li style="font-size:16px!important"> <span  style="font-weight: normal;">Realizar encuestas de servicios que permitan a RedGirasol mejorar sus productos, servicios, plataformas, y contenidos; </span> </li><br>
                                        <li style="font-size:16px!important"> 
                                            <span  style="font-weight: normal;">
                                                Enviar notificaciones, avisos, mensajes promocionales actualizaciones de los servicios y productos,
                                                información técnica, operativa y comercial o comunicaciones con fines de mercadotecnia,
                                                publicidad o telemarketing;
                                            </span> </li><br>
                                        <li style="font-size:16px!important"> <span  style="font-weight: normal;">Incentivar y promocionar, por cualquier medio, los valores corporativos e institucionales de RedGirasol y su cultura organizacional;</span> </li><br>
                                    </ol>
                                </li>
                            </ol>


                        <p class="text-justify">
                            En caso que el Usuario no desee que sus datos personales sean utilizados para todas o algunas de las
                            Finalidades Secundarias que se enumeran en el apartado anterior, el Usuario deberá: (i) enviar un correo
                            electrónico a la dirección <a href="mailto:contacto@redgirasol.com">contacto@redgirasol.com</a> con el asunto “Negativa de Tratamiento” y (ii)
                            posteriormente incluir, dentro del cuerpo de dicho correo, las finalidades que desea restringir, así como los
                            datos de identificación que igualmente desea restringir. Por lo que MutualCode deberá proceder y procesar,
                            dentro de un plazo razonable, dicha solicitud.
                        </p>

                        <br>
                        <strong>4.	¿A quién puede remitir RedGirasol sus datos personales?</strong> <br>
                        <p class="text-justify">
                            RedGirasol podrá remitir total o parcialmente los datos personales de los Usuarios a Prestadores de
                            Servicios que apoyen a RedGirasol en algún proceso. Dichos Prestadores de Servicios incluyen: (i)
                            empresas que tecnología que prestan diversos servicios de telecomunicaciones o infraestructura, y (ii)
                            empresas que prestan servicios de mercadotecnia y estudios de mercado. En todos estos casos, RedGirasol
                            se asegura de que estas empresas asuman obligaciones contractuales que permitan que los datos personales
                            de los Usuarios se encuentren protegidos, además de que se hace del conocimiento de los Prestadores de
                            Servicios, las obligaciones aplicables conforme a la LFPDPPP
                        </p>

                        <br>
                        <strong>5.	¿A quién puede transferir RedGirasol sus datos personales?   </strong>
                        <p class="text-justify">
                            RedGirasol podrá transferir los datos personales de los Usuarios a otras empresas de RedGirasol, Usuarios registrados o no registrados y a autoridades en ciertos casos, para las finalidades establecidas en este Aviso de Privacidad. Estas transferencias incluyen las siguientes:</p>
                        
                        <ol class="text-justify" style="list-style-type: lower-alpha;font-weight: bold; margin-top: 1rem;">
                            <li style="font-size:16px!important"><span  style="font-weight: normal;">RedGirasol puede transmitir los datos personales de los Usuarios a otras empresas de su mismo grupo corporativo. Por ello, los datos pueden ser transferidos a sus empresas filiales, mismas que pueden estar localizadas en México o en otros países. Las filiales de RedGirasol que podrían recibir o consultar los datos personales de los Usuarios han implementado medidas y políticas para la protección de los datos personales de los mismos; estas políticas resultan consistentes con las de RedGirasol y pretenden cumplir con lo dispuesto por las leyes de cada país aplicable.</span> </li><br>
                            <li style="font-size:16px!important"><span  style="font-weight: normal;">Así mismo, RedGirasol podrá transferir datos personales de los Usuarios del Sitio a autoridades administrativas o judiciales, cuando la transferencia sea necesaria o legalmente exigida para la salvaguarda de un interés público, la protección de los derechos de RedGirasol, la protección de derechos de terceros o para la procuración o administración de justicia.</span> </li><br>
                            <li style="font-size:16px!important"><span  style="font-weight: normal;">Así mismo, ocasionalmente, RedGirasol podrá ser parte en alguna transacción corporativa (incluyendo fusiones, escisiones, ventas de activos o acciones, reestructuras corporativas, entre otras transacciones). En tanto que usualmente los Usuarios son uno de los activos a ser considerados como parte de la transacción, RedGirasol podrá comunicar los datos personales de los Usuarios a terceros en el contexto de dichas transacciones. Entre dichos terceros se encuentran, entre otros, los potenciales adquirentes de los activos y los asesores (legales, contables o financieros, entre otros) que participen en la transacción. Únicamente se comunicarán los datos personales a dichos terceros en la medida que dicha comunicación resulte necesaria para evaluar o completar la transacción o cuando la transferencia resulte necesaria como consecuencia de la misma.</span> </li><br>
                            <li style="font-size:16px!important"><span  style="font-weight: normal;">En otros casos, cuando la ley permita esta transferencia sin el consentimiento previo del titular, en términos del artículo 37 de la LFPDPPP. </span> </li>
                        </ol>

                        <p class="text-justify">Todas las transferencias anteriores (y las remisiones incluidas en la cláusula 4 anterior) pueden tener el carácter de nacional o internacional; asimismo, todas son precisas para el mantenimiento o cumplimiento de la relación comercial con los Usuarios y, por lo tanto, no se requiere del consentimiento del Usuario para realizarlas. </p>

                        <br>
                        <strong>6.	¿Con quiénes colabora RedGirasol en materia de datos personales?</strong><br>

                        <ol class="text-justify" style="list-style-type: lower-alpha;font-weight: bold;">
                            <li style="font-size:16px!important"> Proveedores de servicios de TI:  <span  style="font-weight: normal;">RedGirasol utiliza una serie de colaboradores de confianza que le proporcionan servicios de TI y de administración de sistemas, tanto para sus actividades con Usuarios como para sus sistemas internos de administración y TI.</span> </li><br>
                            <li style="font-size:16px!important"> Proveedores de pago y colaboradores de procesamiento:	<span  style="font-weight: normal;"> Para garantizar un proceso de pago seguro y eficaz en línea, mediante facturación o transferencias de dinero.</span> </li><br>
                            <li style="font-size:16px!important"> Colaboradores de almacenamiento en la nube: <span  style="font-weight: normal;">RedGirasol almacena tanto sus propios datos como los de sus Usuarios en centros de almacén de datos seguros.</span> </li><br>
                            <li style="font-size:16px!important"> Colaboradores y agencias para la detección y prevención de fraudes: <span  style="font-weight: normal;">Trabajan de manera conjunta con RedGirasol para garantizar que la misma no sufra ningún tipo de fraude.</span> </li><br>
                            <li style="font-size:16px!important"> Socios comerciales:  <span  style="font-weight: normal;">Para poder ofrecer anuncios, promociones y campañas publicitarias personalizadas, tanto cuando esté interactuando con RedGirasol a través del Sitio o de la Comunidad Girasol o en las redes sociales, como en cualquier otra ocasión.</span> </li><br>
                            <li style="font-size:16px!important"> Colaboradores de redes sociales: <span  style="font-weight: normal;">Para tener presencia y permitirle a los Usuarios interactuar con RedGirasol en las plataformas que utilice.</span> </li><br>
                            <li style="font-size:16px!important"> Proveedores de revisión de encuestas, cuestionarios y productos: <span  style="font-weight: normal;">Ayudan a RedGirasol a garantizar que reciben todas las opiniones y comentarios importantes sobre la experiencia de los Usuarios en el Sitio y en la Comunidad Girasol. </span> </li><br>
                            <li style="font-size:16px!important"> Autoridades en materia fiscal y aduanera, reguladores y demás autoridades competentes:  <span  style="font-weight: normal;">Solicitan informes de actividades de procesamiento y tratamiento en determinadas circunstancias.</span> </li><br>
                            <li style="font-size:16px!important"> Asesores profesionales:  <span  style="font-weight: normal;">Entre ellos abogados, banqueros, auditores y aseguradoras; ofrecen a RedGirasol servicios de asesoramiento y consultoría, banca, asistencia legal, seguros y contabilidad.</span> </li><br>
                        </ol>

                        <br>
                        <strong>7.	¿Cuáles son los derechos de los Usuarios? </strong>
                        <p class="text-justify">
                            <u>Derechos de los Usuarios.</u> De conformidad con la LFDPDPPP, los Usuarios cuentan con los siguientes
                            derechos, mismos que en su conjunto serán referidos como <u>“Derechos ARCO”</u>: <br><br>
                            <ol class="text-justify" style="list-style-type: upper-alpha;font-weight: bold;">
                                <li style="font-size:16px!important">
                                    <span style="font-weight: normal;">
                                        <u>Acceso:</u> Conocer con cuáles de sus datos personales cuenta RedGirasol, cuáles son las
                                        finalidades de su recolección y bajo qué condiciones se utilizan. 
                                    </span>
                                </li> <br>
                                <li style="font-size:16px!important">
                                    <span style="font-weight: normal;">
                                        <u>Rectificación:</u> : Solicitar la corrección de su información personal en caso de que carezca de
                                        alguna actualización, sea inexacta, errónea o esté incompleta.
                                    </span>
                                </li> <br>
                                <li style="font-size:16px!important">
                                    <span style="font-weight: normal;">
                                        <u>Cancelación:</u> Solicitar la eliminación de todo registro o copia de su información personal,
                                        ya sea física o electrónica, cuando el titular considere que su tratamiento no se está
                                        realizando conforme a los principios de la legislación vigente y aplicable, o por cualquier
                                        causa que al efecto considere pertinente. 
                                    </span>
                                </li> <br>
                                <li style="font-size:16px!important">
                                    <span style="font-weight: normal;">
                                        <u>Oposición:</u>Impedir o solicitar que cese el uso de su información personal. 
                                    </span>
                                </li>
                            </ol>

                            <u>Procedimiento para ejercer sus Derechos ARCO.</u> El ejercicio de los derechos establecidos en el presente
                            apartado se podrá llevar a cabo en cualquier momento, mediante (i) solicitud por escrito o correo electrónico
                            enviado al DDP, (ii) con el asunto “DERECHOS ARCO” e (iii) incluyendo en el cuerpo de dicha
                            comunicación, los siguientes: <br><br>
                            <ol class="text-justify" style="list-style-type: upper-alpha; font-weight: bold;">
                                <li style="font-size:16px!important">
                                    <span style="font-weight: normal;">
                                        Nombre completo, domicilio y cualesquier otros datos necesarios para identificar al Usuario en cuestión.
                                    </span>
                                </li><br>
                                <li style="font-size:16px!important">
                                    <span style="font-weight: normal;">
                                        Descripción clara y precisa de los datos personales respecto de los cuales pretende ejercer
                                        alguno de los Derechos ARCO, además de cualquier documento que permita ubicar dichos
                                        datos personales. 
                                    </span>
                                </li><br>
                                <li style="font-size:16px!important">
                                    <span style="font-weight: normal;">
                                        Mención del Derecho ARCO que desea ejercer y la razón que motiva dicho ejercicio. 
                                    </span>
                                </li><br>
                                <li style="font-size:16px!important">
                                    <span style="font-weight: normal;">
                                        Los argumentos que sustenten su posición. 
                                    </span>
                                </li><br>
                                <li style="font-size:16px!important">
                                    <span style="font-weight: normal;">
                                        En caso de que se solicite una rectificación de datos personales, se deberán indicar las
                                        modificaciones a realizar. En este caso concreto, se deberá aportar la documentación que
                                        acredite la razón de los cambios solicitados y aquella que evidencie la veracidad de dicha
                                        información a rectificar. 
                                    </span>
                                </li><br>
                            </ol>

                            De la misma manera, (iv) el Usuario en ejercicio de sus Derechos ARCO deberá acompañar su solicitud,
                            con los siguientes: <br><br>
                            <ol class="text-justify" style="list-style-type: upper-alpha; font-weight: bold;">
                                <li style="font-size:16px!important">
                                    <span style="font-weight: normal;">
                                        Identificación oficial. 
                                    </span>
                                </li><br>
                                <li style="font-size:16px!important">
                                    <span style="font-weight: normal;">
                                        Documento, comunicado o cualquier otro medio de prueba que acredite que su información
                                        personal se encuentra en posesión de RedGirasol.  
                                    </span>
                                </li><br>
                                <li style="font-size:16px!important">
                                    <span style="font-weight: normal;">
                                        En el supuesto que el titular no presente personalmente la solicitud, quien al efecto lo haga
                                        deberá exhibir el instrumento que acredite su debida representación, de conformidad con
                                        lo previsto para dicho efecto por la legislación común de los Estados Unidos Mexicanos;
                                        y, en su caso, la identificación oficial del titular y su respectivo representante legal. 
                                    </span>
                                </li>
                            </ol>

                            Habiendo satisfecho lo previsto en los numerales (i), (ii), (iii) y (iv) anteriores, hace contar con un plazo de
                            20 (veinte) días hábiles contados a partir de la recepción de la comunicación de ejercicio de Derechos
                            ARCO, para procesar, revisar y en su caso, contestar favorablemente o rechazar dicha solicitud, a entera
                            discreción de RedGirasol. No obstante lo anterior, RedGirasol, cuando así lo considere necesario, podrá
                            prorrogar dicho plazo por una sola vez, mediante comunicación por escrito enviada al Usuario en cuestión.
                            Asimismo, RedGirasol podrá solicitarle al Usuario que ejerció sus derechos, la información que considere
                            necesaria para procesar la solicitud de ejercicio Derechos ARCO en comento, incluyendo la entrega o envío
                            de documentación física y original en el domicilio del DDP; en cuyo caso, el Usuario contará con un plazo
                            de 10 (diez) días hábiles para proporcionar dicha información y el plazo aplicable a RedGirasol, se
                            entenderá como suspendido hasta en tanto. Posterior a la recepción de la información adicional solicitada
                            por RedGirasol, RedGirasol contará con un plazo adicional de 20 (veinte) días hábiles para procesar y
                            resolver, negativa o positivamente la solicitud de ejercicio de Derechos ARCO, a entera discreción de
                            RedGirasol. Posterior a la resolución en cuestión, RedGirasol deberá entregar o regresar la información
                            física al Usuario titular, por los medios que considere razonablemente apropiados, dentro de un plazo de 15
                            (quince) días hábiles, mismo que podrá ser prorrogable por una sola vez, mediante notificación al Usuario.
                            <br><br>
                            El incumplimiento del Usuario al procedimiento de ejercicio de Derechos ARCO previsto en el presente
                            apartado será considerado, para todos los efectos legales, como desechado. 
                            <br><br>
                            <u>Revocación del consentimiento por parte del Usuario.</u> El Usuario, en cualquier momento y por cualquier
                            causa, podrá revocar la autorización que le ha otorgado a RedGirasol para tratar sus datos personales. En
                            cuyo caso, el Usuario, por este medio reconoce y acepta que el ejercicio de dicho derecho pudiera implicar,
                            según el alcance y de su revocación, la terminación de la relación comercial o jurídica que sostuviese, en
                            cualquier caso, con RedGirasol y sus Productos y Servicios.
                            <br><br>
                            Para ejercer dicho derecho, el Usuario deberá seguir el procedimiento previsto para ejercer Derechos
                            ARCO, con las siguientes modificaciones: el asunto del correo deberá leer “Revocación del
                            Consentimiento” y se deberá de agregar, como parte del cuerpo de la comunicación la fecha a partir de la
                            cual desea el Usuario en cuestión que se haga efectiva la revocación de su consentimiento.
                            <br><br>
                            <u>Medios para limitar el uso o divulgación de los datos personales del Usuario.</u> Aunado a las manifestaciones
                            de RedGirasol, respecto de la protección de los datos personales de los Usuarios. RedGirasol le ofrece a los
                            Usuarios la posibilidad de tomar decisiones sobre cómo usa RedGirasol sus datos personales; para lo cual,
                            RedGirasol le ofrece al Usuario diversos medios para informarse acerca de su decisión. 
                            <br><br>
                            Motivo por el cual, RedGirasol cuenta con mecanismos distintos al ejercicio de Derechos ARCO para
                            limitar el uso y la divulgación de los datos personales de los Usuarios, mismos que limitativamente, se
                            enuncian a continuación: <br><br>
                            <ol class="text-justify" style="list-style-type: upper-alpha;font-weight: bold;">
                                <li style="font-size:16px!important">
                                    <span style="font-weight: normal;">
                                        Para efectos de evitar recibir publicidad en general, el Usuario puede inscribirse en el
                                        Registro Público para Evitar Publicidad (REPEP) de la Procuraduría Federal del
                                        Consumidor (PROFECO), a través de la siguiente liga: <a href="https://repep.profeco.gob.mx/ " target="_blank" rel="noopener noreferrer">https://repep.profeco.gob.mx/ </a>
                                    </span>
                                </li><br>
                                <li style="font-size:16px!important">
                                    <span style="font-weight: normal;">
                                        Para efectos de evitar recibir publicidad de servicios financieros, el Usuario puede
                                        inscribirse en el Registro Público de Usuarios (REUS) de la Comisión Nacional para la
                                        Protección y Defensa de los Usuarios de Servicios Financieros (CONDUSEF), a través de
                                        la siguiente liga: <a href="https://webapps.condusef.gob.mx/reus/ReusDW4/index.html " target="_blank" rel="noopener noreferrer">https://webapps.condusef.gob.mx/reus/ReusDW4/index.html </a>
                                    </span>
                                </li>
                            </ol>
                            En caso que el titular desee conocer más sobre los procedimientos mencionados en este apartado, podrá
                            enviar un correo electrónico al DDP con el asunto “Limitación de Uso o Divulgación”, expresando sus
                            dudas, comentarios y sugerencias al respecto. 
                        </p>
                        <br>
                        <strong>8.	Almacenamiento de los datos personales de los Usuarios. </strong> <br>
                        <p class="text-justify">
                            En relación con lo contenido en el presente Aviso de Privacidad y en atención a las limitaciones y
                            excepciones previstas por la LFPDPPP y sus ordenamientos secundarios, todos los datos personales
                            recolectados de los Usuarios y almacenados por RedGirasol en soportes físicos, electrónicos, ópticos o
                            electromagnéticos, serán resguardados por el tiempo en que el Usuario mantenga una relación comercial o
                            jurídica con RedGirasol. Satisfechas las finalidades para los cuales fueron recabados los datos personales
                            de los Usuarios, dichos datos personales serán sometidos a un periodo de bloqueo, para su posterior
                            destrucción, de conformidad con la LFPDPPP y sus ordenamientos secundarios. 
                        </p>

                        <br>
                        <strong>9.	Seguridad de sus Datos Personales.</strong><br>
                        <p class="text-justify">
                            RedGirasol ha adoptado medidas de seguridad físicas, organizacionales, y técnicas para proteger los datos
                            personales de los Usuarios en contra de pérdida, uso o acceso no autorizado, en cumplimiento de lo
                            dispuesto por la LFPDPPP. 
                        </p>

                        <br>
                        <strong>10.	Modificaciones al Aviso de Privacidad. </strong><br>
                        <p class="text-justify">
                            RedGirasol se reserva el derecho de efectuar en cualquier momento modificaciones o actualizaciones al
                            presente Aviso de Privacidad. El aviso modificado será publicado en lugares visibles en el Sitio o se le hará
                            llegar al Usuario a través del correo electrónico proporcionado, en caso de contar con información de
                            contacto. Los Usuarios podrán verificar que el Aviso de Privacidad ha sido modificado en virtud de que
                            RedGirasol hará notar siempre la fecha de su última actualización. En caso que RedGirasol realice algún
                            cambio sustancial en el tratamiento de los datos personales de los Usuarios, éstas modificaciones se le harán
                            saber a los mismos a través de una notificación a los correos electrónicos de los Usuarios, cuando se cuente
                            con datos de contacto, o anunciando estos cambios en el Sitio. Todo cambio en el Aviso de Privacidad
                            entrará en vigor a los 15 (quince) días naturales de su respectiva publicación. Si el Usuario no está de
                            acuerdo con las modificaciones, podrá contactar al DDP. 
                        </p>

                        <br>
                        <strong>11. Miscelánea</strong><br>

                        <p class="tex-justify">
                            No obstante cualquier estipulación en contrario contenida en el presente y cualesquier otro documento
                            referente o relacionado con RedGirasol, el Sitio o la Comunidad Girasol, el acceso al Sitio o a la Comunidad
                            Girasol por cualquier plataforma o medio establece el consentimiento expreso y plano del presente Aviso
                            de Privacidad. Cualquier modificación a este Aviso de Privacidad se dará a conocer a través del correo
                            electrónico que sea proporcionado en el Sitio.
                        </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
  name: "LatestPrivacyContract"
}
</script>

<style scoped>

</style>